module.exports = function hero(elem) {
    const img = new Image();
    img.src = "./assets/images/hero1.jpg";
    img.onload = () => {
        document
            .querySelector(".hero")
            .setAttribute("style", `background-image: url(${img.src});`);
        document.querySelector('.hero-description').style.opacity = 1;
        // document.querySelector(".hero").classList.add("loaded");
    };

};