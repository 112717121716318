module.exports = function filter() {

    let inputsCheckbox = Array.from(document.querySelectorAll('[type="checkbox"]'));
    let inputsText = Array.from(document.querySelectorAll('[type="number"]'));
    let selectOptions = Array.from(document.querySelectorAll('option'));
    let str = {};
    let keyValues = '';
    let newStr = '';
    let searchArr = window.location.search.split('&');
    let searchFilterParams = '';
    let searchFilterOrder = '';
    let searchObj = {};
    let searchArrItems = [];
    let filterBtn = document.querySelector('.filter-btn');
    let selects = document.querySelectorAll('select[data-order]');


    for (let i = 0; i < searchArr.length; i++) {
        if (searchArr[i][0] == 'q' || searchArr[i][1] == 'q') {
            searchFilterParams = searchArr[i];
        }
        if (searchArr[i][0] == 'o' || searchArr[i][1] == 'o') {
            searchFilterOrder = searchArr[i];
        }
    }
    searchFilterParams = searchFilterParams.replace('?q=', '').split('__');
    searchFilterOrder = searchFilterOrder.replace('?o=', '').replace('o=', '');
    //сортировка по параметрам
    function reorder(selectValue) {
        let _search = location.search.replace(/(\?|\&)?o=(id|price|year)_(A|DE)SC/g, '');
        if (_search.length) {
            _search += '&';
        } else {
            _search += '?';
        }
        return location.search = _search + 'o=' + selectValue;
    }
    for (let i = 0; i < selects.length; i++) {
        selects[i].addEventListener('input', e => reorder(e.target.value))
    }



    document.querySelector('#filter-control').addEventListener('submit', onSubmit);
    //обработка формы при сабмите
    function onSubmit(event) {
        event.preventDefault();

        //добавление чекбоксов в объект
        for (let i = 0; i < inputsCheckbox.length; i++) {
            if (inputsCheckbox[i].checked) {
                if (str.hasOwnProperty(inputsCheckbox[i].dataset.name)) {
                    str[inputsCheckbox[i].dataset.name].push(inputsCheckbox[i].value);
                } else {
                    str[inputsCheckbox[i].dataset.name] = [inputsCheckbox[i].value];
                }
            }
        }

        //добавление инпутов в объект
        for (let i = 0; i < inputsText.length; i++) {
            if (inputsText[i].value) {
                if (str.hasOwnProperty(inputsText[i].dataset.name)) {
                    str[inputsText[i].dataset.name].push(inputsText[i].value);
                } else {
                    str[inputsText[i].dataset.name] = [inputsText[i].value];
                }
            }
        }
        //проверка на незаполненную форму
        if (Object.keys(str).length == 0) {
            window.location.href = window.location.href.split('?')[0];
            return;
        }
        //преобразование объекта в строку 
        for (key in str) {
            //записываем по циклу все его значения в строку
            for (let i = 0; i < str[key].length; i++) {
                keyValues += '_' + str[key][i];
            }
            newStr += key + keyValues + '__';
            keyValues = '';
        }
        //удаляем два символа в конце
        newStr = newStr.substr(0, newStr.length - 2)
        //заносим строку в инпут
        document.querySelector('[name="q"]').value = newStr

        let form = event.target;

        form.submit();
    }


    //заполнение формы после её обработки

    //преобразование из поисковой строки в объект
    for (let i = 0; i < searchFilterParams.length; i++) {
        searchArrItems = searchFilterParams[i].split('_');
        searchObj[searchArrItems[0]] = [];
        for (let j = 1; j < searchArrItems.length; j++) {
            searchObj[searchArrItems[0]].push(searchArrItems[j]);
        }
    }

    //заполнение чекбоксов
    for (let i = 0; i < inputsCheckbox.length; i++) {
        if (searchObj.hasOwnProperty(inputsCheckbox[i].dataset.name)) {
            if (searchObj[inputsCheckbox[i].dataset.name].includes(inputsCheckbox[i].value)) {
                inputsCheckbox[i].checked = 'true';
            }
        }
    }
    //заполнение инпутов
    for (let i = 0; i < inputsText.length; i++) {
        if (searchObj.hasOwnProperty(inputsText[i].dataset.name)) {
            inputsText[i].value = searchObj[(inputsText[i].dataset.name)];
        }
    }

    //заполнение селекта
    for (let i = 0; i < selectOptions.length; i++) {
        selectOptions[i].removeAttribute('selected');
        if (selectOptions[i].value == searchFilterOrder) {
            selectOptions[i].setAttribute('selected', true)
        }
    }


    //показ фильтра для мобильной версии
    filterBtn.addEventListener('click', function () {
        filterBtn.classList.toggle('filter-btn-active');
        if (filterBtn.classList.contains('filter-btn-active')) {
            document.querySelector('aside').style.left = '0px';
        } else {
            document.querySelector('aside').style.left = '-240px';
        }
    })
}