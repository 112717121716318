module.exports = function menu() {
    var logo = document.querySelector('.header-logo');
    var lastScroll = 0;
    var burger = document.querySelector('.burger');
    var nav = document.querySelector('.navigation');


    nav.classList.add('navigation-active');
    burger.classList.add('burger-load');
    burger.style.opacity = '1';
    logo.classList.add('header-logo-load');
    logo.style.opacity = '1';

    //burger
    burger.addEventListener('click', () => {
        burger.classList.toggle('burger-active');
        document.querySelector('nav').classList.toggle('nav-active');
    })
    //показ хедера при скороле вверх
    window.addEventListener('scroll', () => {

        var scrollTop = window.pageYOffset;
        if (lastScroll > scrollTop) {
            document.querySelector('header').style.top = '0px'
        } else if (lastScroll < scrollTop) {
            document.querySelector('header').style.top = '-70px'
        }
        lastScroll = scrollTop;

    })
};
