module.exports = function about() {
    var arrowMargin = document.querySelector('.center-section').clientWidth;
    var arrowsLeft = Array.from(document.querySelectorAll('.left-section .arrow'));
    var arrowsRight = Array.from(document.querySelectorAll('.right-section .arrow'));
    var trianglesLeft = Array.from(document.querySelectorAll('.left-section .triangle'));
    var trianglesRight = Array.from(document.querySelectorAll('.right-section .triangle'));
    var a = arrowsLeft[0].clientHeight / 2;
    var iconCircles = document.querySelectorAll('.icon-circle');
    for (let i = 0; i < arrowsLeft.length; i++) {
        arrowsLeft[i].style.marginRight = `${-arrowMargin}px`;
    }
    for (let i = 0; i < trianglesLeft.length; i++) {
        trianglesLeft[i].style.borderTop = `${a}px solid transparent`;
        trianglesLeft[i].style.borderBottom = `${a}px solid transparent`;
        trianglesLeft[i].style.borderRight = `${a}px solid #ffffff`;
        trianglesLeft[i].style.marginLeft = `-${a}px`;
    }


    for (let i = 0; i < arrowsRight.length; i++) {
        arrowsRight[i].style.marginLeft = `${-arrowMargin}px`;
    }
    for (let i = 0; i < trianglesRight.length; i++) {
        trianglesRight[i].style.borderTop = `${a}px solid transparent`;
        trianglesRight[i].style.borderBottom = `${a}px solid transparent`;
        trianglesRight[i].style.borderLeft = `${a}px solid #ffffff`;
        trianglesRight[i].style.marginRight = `-${a}px`;
    }
    for (let i = 0; i < iconCircles.length; i++) {
        iconCircles[i].style.width = iconCircles[i].clientHeight + 'px';

    }
};
